
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-6 col-md-6 footer-contact">
            <!-- <h3>PHẦN MỀM QUẢN LÝ PHÒNG KHÁM DTZSOFT</h3> -->
            <img src="assets/img/logo/dtzsoft-logo.png" style="margin-left: -10px;margin-bottom: 10px;">
            <p>
              <strong>Địa chỉ:</strong> 33 Phan Văn Sửu, P.13, Q.Tân Bình, TP.HCM<br>
              <strong>Điện thoại / Zalo / Viber:</strong> 0888 375 345 - 0903 006 805<br>
              <strong>Email:</strong> dtzsoft@gmail.com<br>
              <strong>Website:</strong> https://dtzsoft.com<br>
              <strong>Facebook:</strong> https://www.facebook.com/DTZSoft/<br>
            </p>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Liên kết</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Giới thiệu</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/huong-dan-su-dung">Hướng dẫn sử dụng</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Điều khoản sử dụng</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Hình thức thanh toán</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/lien-he">Liên hệ</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Kết nối với chúng tôi</h4>
            <div class="social-links mt-3">
              <a href="https://twitter.com/DtzSoft" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="https://www.facebook.com/DTZSoft" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-4">
      <div class="copyright">
        &copy; Copyright <strong><span>DTZSoft.com</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        Designed by <a href="https://dtzsoft.com">DTZSoft</a>
      </div>
    </div>
  </footer>
  <!-- End Footer -->