import { Component, OnInit, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import {FormControl,FormGroup,Validators,FormBuilder,} from "@angular/forms";
import {Router} from '@angular/router';
import { V1Service } from '~/app/core/v1.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { notPhoneNumber, notEmail } from "~/app/shared/helper/validator/validator";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  validateForm!: FormGroup;
  isConfirmLoading = false;
  dataForm: any;

  constructor(
    private fb: FormBuilder,
    private v1Service: V1Service,
    private modal: NzModalService,
    ) {
  }
  
  isVisible = false;
  dataLoaiHinh = [];
  dataTinhThanh = [];
  dataQuanHuyen = [];
  dataPhuongXa = [];
  dataGoi = [];
  MaGoi = null;
  isLoaddingLoaiHinh = false;
  isLoaddingGoi = false;
  isLoaddingTinhThanh = false;
  isLoaddingQuanHuyen = false;
  isLoaddingPhuongXa = false;

  ngOnInit() {
    this.validateForm = this.fb.group({
      TenPhongKham: [null, [Validators.required]],
      MaTinhThanh: [null, [Validators.required]],
      MaQuanHuyen: [null],
      MaPhuongXa: [null],
      DiaChi: [null],
      DienThoai: [null, [Validators.required, notPhoneNumber()]],
      Email: [null, [Validators.required, notEmail()]],
      MaLoaiHinh: [null, [Validators.required]],
      NguoiLienHe: [null, [Validators.required]],
      MaGoi: [null, [Validators.required]],
    });
    this.clearData();
  }

  clearData() {
    for (const i in this.validateForm.controls) {
      //this.validateForm.controls[i].clearValidators();
      this.validateForm.controls[i].markAsPristine();
      this.validateForm.controls[i].markAsUntouched();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    this.dataForm = {
      TenPhongKham: null,
      DiaChi: null,
      DienThoai: null,
      Email: null,
      MaTinhThanh: null,
      MaQuanHuyen: null,
      MaPhuongXa: null,
      MaLoaiHinh: null,
      NguoiLienHe: null,
      MaGoi: this.MaGoi
    };
  }

  getLoaiHinh() {
    this.isLoaddingLoaiHinh = true;
    this.v1Service.LoaiHinh().subscribe(
      (res: any) => {
        if (res.Code == 1) {
          this.dataLoaiHinh = res.Data;
        } 
        // else {
        //   this.toast.error(this.translate.instant("global_fail"));
        // }
        this.isLoaddingLoaiHinh = false;
      },
      (error) => {
        console.log(error);
        //this.toast.error(this.translate.instant('global_error_fail'));
        this.isLoaddingLoaiHinh = false;
      }
    );
  }

  getGoi() {
    this.isLoaddingGoi = true;
    this.v1Service.Goi().subscribe(
      (res: any) => {
        if (res.Code == 1) {
          this.dataGoi = res.Data;
        } 
        // else {
        //   this.toast.error(this.translate.instant("global_fail"));
        // }
        this.isLoaddingGoi = false;
      },
      (error) => {
        console.log(error);
        //this.toast.error(this.translate.instant('global_error_fail'));
        this.isLoaddingGoi = false;
      }
    );
  }

  getTinhThanh() {
    this.isLoaddingTinhThanh = true;
    this.v1Service.TinhThanh().subscribe(
      (res: any) => {
        if (res.Code == 1) {
          this.dataTinhThanh = res.Data;
        } 
        // else {
        //   this.toast.error(this.translate.instant("global_fail"));
        // }
        this.isLoaddingTinhThanh = false;
      },
      (error) => {
        console.log(error);
        //this.toast.error(this.translate.instant('global_error_fail'));
        this.isLoaddingTinhThanh = false;
      }
    );
  }
  tinhThanhChange($event){
    this.dataQuanHuyen = [];
    if($event)
    {
      this.getQuanHuyen($event);
    }
  }

  getQuanHuyen(MaTinhThanh: any) {
    this.isLoaddingQuanHuyen = true;
    this.v1Service.QuanHuyen(MaTinhThanh).subscribe(
      (res: any) => {
        if (res.Code == 1) {
          this.dataQuanHuyen = res.Data;
        } 
        // else {
        //   this.toast.error(this.translate.instant("global_fail"));
        // }
        this.isLoaddingQuanHuyen = false;
      },
      (error) => {
        console.log(error);
        //this.toast.error(this.translate.instant('global_error_fail'));
        this.isLoaddingQuanHuyen = false;
      }
    );
  }
  quanHuyenChange($event){
    this.dataPhuongXa = [];
    if($event)
    {
      this.getPhuongXa($event);
    }
  }

  getPhuongXa(MaQuanHuyen: any) {
    this.isLoaddingPhuongXa = true;
    this.v1Service.PhuongXa(MaQuanHuyen).subscribe(
      (res: any) => {
        if (res.Code == 1) {
          this.dataPhuongXa = res.Data;
        } 
        // else {
        //   this.toast.error(this.translate.instant("global_fail"));
        // }
        this.isLoaddingPhuongXa = false;
      },
      (error) => {
        console.log(error);
        //this.toast.error(this.translate.instant('global_error_fail'));
        this.isLoaddingPhuongXa = false;
      }
    );
  }

  open(MaGoi: any): void {
    if(MaGoi) {
      this.MaGoi = MaGoi;
    }
    this.isVisible = true;
    this.getLoaiHinh();
    this.getGoi();
    this.getTinhThanh();
    this.clearData();
  }
  
  close(): void {
    this.isVisible = false;
  }

  updateDienThoaiValidator(): void {
    Promise.resolve().then(() => this.validateForm.controls.DienThoai.updateValueAndValidity());
  }

  onSubmit(){
    //Kiểm tra validate
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.invalid) return;
    
    let data = this.validateForm.value;
    this.isConfirmLoading = true;
    this.v1Service.DangKy(data).subscribe(
      (res: any) => {
        if (res.Code == 1) {
          this.modal.success({
            nzTitle: 'Đăng ký thành công!',
            nzContent: 'Chúng tôi sẽ liên hệ lại quý khách hàng trong thời gian sớm nhất!'
          });
          this.close();
        } else {
          //this.toast.warning(this.translate.instant("global_add_fail"));
        }
        this.isConfirmLoading = false;
      },
      (error) => {
        console.log(error);
        //this.toast.error(this.translate.instant('global_error_fail'));
        this.isConfirmLoading = false;
      }
    );
  }
}
