<main id="main">
  <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Liên hệ</h2>
        <ol>
          <li><a href="/">Trang chủ</a></li>
          <li>Liên hệ</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs Section -->

  <section id="contact" class="contact">
    <div class="container aos-init aos-animate" data-aos="fade-up">

      <div class="section-title">
        <h2>THÔNG TIN LIÊN HỆ</h2>
        <p>Mọi thắc mắc về dịch vụ xin Quý khách hàng vui lòng liên hệ theo thông tin liên hệ bên dưới để được tư vấn & hỗ trợ</p>
      </div>

      <div class="row">

        <div class="col-lg-5 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Địa chỉ:</h4>
              <p>33 Phan Văn Sửu, P.13, Q.Tân Bình, TP.HCM</p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>dtzsoft@gmail.com</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Điện thoại / Zalo / Viber:</h4>
              <p>0888 375 345 - 0903 006 805</p>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4660.972161760814!2d106.63951125870456!3d10.781045310398016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752c5e43703e57%3A0xf8e672f422909d9a!2zUEjhuqZOIE3hu4BNIFFV4bqiTiBMw50gUEjDkk5HIEtIw4FNIE5ISSBEVFpTT0ZU!5e0!3m2!1svi!2s!4v1562464962559!5m2!1svi!2s" style="border:0" allowfullscreen="" width="100%" height="450" frameborder="0"></iframe>
          </div>

        </div>

        <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">Your Name</label>
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required="">
              </div>
              <div class="form-group col-md-6 mt-3 mt-md-0">
                <label for="name">Your Email</label>
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required="">
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="name">Subject</label>
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required="">
            </div>
            <div class="form-group mt-3">
              <label for="name">Message</label>
              <textarea class="form-control" name="message" rows="10" required=""></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>

      </div>

    </div>
  </section>
</main>