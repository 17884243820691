<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="close()" [nzKeyboard]="false" [nzMaskClosable]="false" nzWidth="800px">
    <ng-template #modalTitle>ĐĂNG KÝ DÙNG THỬ VÀ TRẢI NGHIỆM HỆ THỐNG!</ng-template>
    <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm" nzLayout="horizontal">
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24">Tên phòng khám <span style="font-weight:normal">(*)</span></nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng nhập tên phòng khám!">
                <input nz-input formControlName="TenPhongKham" [(ngModel)]="dataForm.TenPhongKham" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24">Loại hình <span style="font-weight:normal">(*)</span></nz-form-label>
                <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng chọn loại hình!">
                  <nz-select formControlName="MaLoaiHinh" [(ngModel)]="dataForm.MaLoaiHinh" [nzLoading]="isLoaddingLoaiHinh" nzShowSearch nzAllowClear>
                    <nz-option *ngFor="let item of dataLoaiHinh" [nzLabel]="item.Ten" [nzValue]="item.Ma"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24">Gói dịch vụ <span style="font-weight:normal">(*)</span></nz-form-label>
                <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng chọn gói dịch vụ!">
                  <nz-select formControlName="MaGoi" [(ngModel)]="dataForm.MaGoi" [nzLoading]="isLoaddingGoi" nzShowSearch nzAllowClear>
                    <nz-option *ngFor="let item of dataGoi" [nzLabel]="item.Ten" [nzValue]="item.Ma"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="MaTinhThanh">Tỉnh thành <span style="font-weight:normal">(*)</span></nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng chọn tỉnh thành!">
                <nz-select formControlName="MaTinhThanh"  [(ngModel)]="dataForm.MaTinhThanh" [nzLoading]="isLoaddingTinhThanh" (ngModelChange)="tinhThanhChange($event)" nzShowSearch nzAllowClear>
                  <nz-option *ngFor="let item of dataTinhThanh" [nzLabel]="item.Ten" [nzValue]="item.Ma"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="MaQuanHuyen">Quận huyện</nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng chọn quận huyện!">
                <nz-select formControlName="MaQuanHuyen" id="MaQuanHuyen" [(ngModel)]="dataForm.MaQuanHuyen" [nzLoading]="isLoaddingQuanHuyen" (ngModelChange)="quanHuyenChange($event)" nzShowSearch nzAllowClear>
                  <nz-option *ngFor="let item of dataQuanHuyen" [nzLabel]="item.Ten" [nzValue]="item.Ma"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="MaPhuongXa">Phường xã</nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng chọn phường xã!">
                <nz-select formControlName="MaPhuongXa" id="MaPhuongXa" [(ngModel)]="dataForm.MaPhuongXa" [nzLoading]="isLoaddingPhuongXa" nzAllowClear nzShowSearch >
                  <nz-option *ngFor="let item of dataPhuongXa" [nzLabel]="item.Ten" [nzValue]="item.Ma"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="DiaChi">Số nhà/ đường</nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24">
                <input nz-input formControlName="DiaChi" id="DiaChi" [(ngModel)]="dataForm.DiaChi" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24">Người đại diện <span style="font-weight:normal">(*)</span></nz-form-label>
                <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="Vui lòng nhập người đại diện!">
                  <input nz-input formControlName="NguoiLienHe" [(ngModel)]="dataForm.NguoiLienHe" />
                </nz-form-control>
              </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="DienThoai">Điện thoại <span style="font-weight:normal">(*)</span></nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24" [nzErrorTip]="errorPhone">
                <input nz-input formControlName="DienThoai" id="DienThoai" [(ngModel)]="dataForm.DienThoai" (ngModelChange)="updateDienThoaiValidator()"/>
                <ng-template #errorPhone let-control>
                  <ng-container *ngIf="control.hasError('required')">
                   Vui lòng nhập số điện thoại!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('notPhoneNumber')">
                   Số điện thoại không đúng định dạng!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="Email">Email <span style="font-weight:normal">(*)</span></nz-form-label>
              <nz-form-control [nzSm]="18" [nzXs]="24" [nzErrorTip]="errorEmail">
                <input nz-input type="email" formControlName="Email" id="Email" [(ngModel)]="dataForm.Email" />
                <ng-template #errorEmail let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Vui lòng nhập email!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('notEmail')">
                    Email không đúng định dạng!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>
    </ng-template>
    <ng-template #modalFooter>
        <span style="float: left;font-size: 15px;text-align: left;color: red;">Lưu ý: Sau khi quý khách gửi nội dung đăng ký, chúng tôi sẽ liện hệ lại quý khách trong thời gian sớm nhất! Chúng tôi sẽ gửi thông tin tài khoản đăng nhập vào email của quý khách. Quý khách vui lòng cung cấp đúng tài khoản email!</span>
        <button nz-button nzDanger  nzType="primary" (click)="onSubmit()" [nzLoading]="isConfirmLoading">ĐĂNG KÝ DÙNG THỬ</button>
        <button nz-button nzType="default" (click)="close()">Đóng</button>
    </ng-template>
</nz-modal>