import { Component, OnInit, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import {Router} from '@angular/router';
import { DataService } from '~/app/core/data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor( 
    private dataService: DataService,
  ) {
  }

  showMobile = false;

  ngOnInit() {
  }

  onMenu()
  {
    this.showMobile = !this.showMobile;
  }

  openRegister()
  { 
    let data:any = {};
    data.type = "register";
    this.dataService.changeMessage(data);
  }
}
