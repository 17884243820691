import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from './Constant';
import { cloneObject } from '~/app/shared/helper/object/clone.object';

@Injectable({
	providedIn: 'root'
})

export class V1Service {
	apiUrl = environment.backEndApiURL;

	constructor(
		private http: HttpClient, 
		public router: Router) {	
	}
    
    LoaiHinh(){
		const url = this.apiUrl.concat(Constant.API) + "/LoaiHinh";
		return this.http.get<any>(url);
	}

    Goi(){
		const url = this.apiUrl.concat(Constant.API) + "/Goi";
		return this.http.get<any>(url);
	}

    TinhThanh(){
		const url = this.apiUrl.concat(Constant.API) + "/TinhThanh";
		return this.http.get<any>(url);
	}

    QuanHuyen(MaTinh){
		const url = this.apiUrl.concat(Constant.API) + "/QuanHuyen?MaTinh=" + MaTinh;
		return this.http.get<any>(url);
	}

    PhuongXa(MaQuan){
		const url = this.apiUrl.concat(Constant.API) + "/PhuongXa?MaQuan=" + MaQuan;
		return this.http.get<any>(url);
	}
	
    DangKy(req: any){
        let dataRequest = cloneObject(req)
		const url = this.apiUrl.concat(Constant.API) + "/DangKy";
		return this.http.post<any>(url, dataRequest);
	}
}
