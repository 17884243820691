import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from "@angular/common";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { RegisterComponent } from './shared/register/register.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
// import { NZ_I18N } from 'ng-zorro-antd/i18n';
// import { vi_VN, en_US } from 'ng-zorro-antd/i18n';
import { SharedModule } from "./shared/shared.module";
import { LOCALE_ID } from '@angular/core';
// import { NzI18nService } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { vi_VN, en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { vi } from 'date-fns/locale';
import { MarkdownModule } from 'ngx-markdown';
import { ContactComponent } from './pages/contact/page.component';

//registerLocaleData(en);
//registerLocaleData(vi);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    RegisterComponent,
    ContactComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzModalModule,
    NzButtonModule,
    NzInputModule,
    NzFormModule,
    NzInputNumberModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    {
      provide: NZ_I18N,
      useValue: vi_VN,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en':
            return en_US;
          case 'vi':
            return vi_VN;
          default:
            return vi_VN;
        }
      },
      deps: [LOCALE_ID]
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private i18n: NzI18nService) { 
    this.i18n.setDateLocale(vi);
  }
}