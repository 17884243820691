import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactComponent } from './pages/contact/page.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: 'huong-dan-su-dung', loadChildren: () => import('./docs/docs.module').then(m => m.DocsModule) },
  { path: 'lien-he', component: ContactComponent },
  { path: '**', redirectTo: 'error'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", onSameUrlNavigation: 'reload', })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
