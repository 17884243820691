import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { RegisterComponent } from './shared/register/register.component';
import { DataService } from '~/app/core/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  @ViewChild(RegisterComponent) registerModal;
  title = 'DTZSoft';
  isCollapsed = false;
  showSidebar: boolean = false;
  showNavbar: boolean = false;
  showFooter: boolean = false;
  isLoading: boolean;
  showSeting = false;
  showLoading = false;

  constructor(
      private router: Router, 
      private ref: ChangeDetectorRef,
      private dataService: DataService,
     ) {
  }

  ngOnInit() {
    this.dataService.currentMessage.subscribe(message => this.onReceiveCall(message));
  }
  
  onReceiveCall(data: any) {
    if(data.type == "register")
    {
      this.registerModal.open(data.MaGoi);
    }
  }
}
