
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <!-- Uncomment below if you prefer to use an image logo -->
        <a href="/#"><img src="assets/img/logo/dtzsoft-logo.png" alt="" class="img-fluid"></a>
      </div>

      <nav id="navbar" class="navbar" [ngClass]="showMobile ? 'navbar-mobile': ''">
        <ul>
          <li><a class="nav-link scrollto active" href="/#">Trang chủ</a></li>
          <li><a class="nav-link scrollto" href="#gioi-thieu">Giới thiệu</a></li>
          <li><a class="nav-link scrollto" href="#tinh-nang">Tính năng</a></li>
          <li><a class="nav-link scrollto" href="#bang-gia">Bảng giá</a></li>
          <li><a class="nav-link scrollto" href="#doi-ngu">Đội ngũ</a></li>
          <li><a class="nav-link scrollto" href="/huong-dan-su-dung">Hướng dẫn sử dụng</a></li>
          <li><a class="nav-link scrollto" href="/lien-he">Liên hệ</a></li>
          <li><a class="nav-link scrollto" href="https://soft.dtzsoft.com">Đăng nhập</a></li>
          <li><a class="getstarted scrollto" (click)="openRegister()">ĐĂNG KÝ</a></li>
        </ul>
        <i class="bi mobile-nav-toggle" [ngClass]="showMobile ? 'bi-x': 'bi-list'" (click)="onMenu()"></i>
      </nav><!-- .navbar -->

    </div>
  </header>
  <!-- End Header -->