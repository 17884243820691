import { Component, OnInit } from '@angular/core';

declare const Swiper: any;
@Component({
  selector: 'app-contact',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class ContactComponent implements OnInit {
  constructor(
    ) {
  }

  ngOnInit() {
  }
}
